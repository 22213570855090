<template>
  <div>
    <el-row :gutter="20" class="search-box">
      <el-col :span="4">
        <el-select
          v-model="query.operationId"
          placeholder="请选择代理商"
          :maxLength="50"
          @change="getList(1)"
          clearable
        >
          <el-option
            v-for="(item, i) in agentList"
            :value="item.id+''"
            :label="item.operationName"
            :key="i"
          ></el-option>
        </el-select>
      </el-col>


      <el-col :span="4">
        <el-button type="primary" @click="getList(1)">查询</el-button>
      </el-col> 
    </el-row>
    <div>
      
    </div>
    
    <el-table
      border
      :data="dataSource"
      class="page-top-space"
      row-class-name="table-row"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column label="id" prop="id" width="50"></el-table-column>
      <el-table-column label="昵称" prop="nickName">
        <template #default="scope">
          <div>
            <span v-if="scope.row.realName">{{ scope.row.realName }} </span>
            <span v-else>未实名 </span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="手机号" prop="phone" align="right">
      </el-table-column>
      <!-- <el-table-column label="押金状态" prop="levelName"></el-table-column> -->
      <el-table-column label="押金金额" prop="money">
        <template #default="scope">
          <span>{{ util.formatNum(scope.row.money / 100) }}</span>
        </template>
      </el-table-column>

      <el-table-column label="套餐到期时间" prop="endTime">
        <template #default="scope">
          <span v-if="!validatenull(scope.row.endTime)">{{
            this.$filter.dateFormat(new Date(scope.row.endTime))
          }}</span>
        </template>
      </el-table-column>

      <el-table-column label="套餐剩余时间" prop="endTime">
        <template #default="scope">
          <span v-if="!validatenull(scope.row.endTime)">{{
            this.util.formatDay(
              (new Date(scope.row.endTime) - new Date()) / 1000
            )
          }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="使用状态" prop="account"></el-table-column> -->
      <el-table-column label="实名认证" prop="hasVerify">
        <template #default="scope">
          <eb-badge
            :list="this.const.HAS_VERIFY"
            :target="scope.row.hasVerify"
          ></eb-badge>
        </template>
      </el-table-column>

      <el-table-column label="邀请人" prop="inviteName">
        <template #default="scope">
          <span> {{ scope.row.inviteRealName || "自然人" }}</span>
        </template>
      </el-table-column>

      <el-table-column label="用户类型" prop="isPusher">
        <template #default="scope">
          <eb-badge
            :list="this.const.PUSHER_USER_TYPE"
            :target="scope.row.isPusher"
          ></eb-badge>
        </template>
      </el-table-column>

      <el-table-column label="状态" prop="status">
        <template #default="scope">
          <eb-badge
            :list="this.const.STATUS"
            :target="scope.row.status"
          ></eb-badge>
        </template>
      </el-table-column>

      <el-table-column label="注册时间" prop="createAt">
        <template #default="scope">
          <span>
            {{ this.$filter.dateFormat(new Date(scope.row.createAt)) }}
          </span>
        </template>
      </el-table-column>

      <el-table-column label="操作">
        <template #default="scope">
          <!-- <span class="option option-primary" @click="doEnd(scope.row)"  v-auth="this.per.AGENT_UPDATA"
            >结束租借</span> -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout=" total,prev, pager, next"
      background
      :total="total"
      :page-size="pages.pageSize"
      :current-page="pages.pageIndex"
      @current-change="getList"
    ></el-pagination>
  </div>
</template>

<script>
import { agentUserList} from "@/api/agent";
import { areas } from "@/common/area.js";
import { mapState } from "vuex";
export default {
  computed: mapState({
    agentList: (state) => {
      return state.agent.agentList;
    },
  }),
  data() {
    return {
      dataSource: [],
      pages: {
        pageIndex: 1,
        pageSize: 10,
      },
      query: {},
      modalData: {},
      modalType: "add",
      MODAL_KEY: {
        EDIT_MODAL: "EDIT_MODAL",
      },
      total: 0,
    };
  },
  methods: { 
    doEnd(data){
      this.$confirm("是否要结束租借", "提示", {
        type: "warning",
      }).then((res) => {
        doEnd({
          userId: data.id,
        }).then((res) => {
          this.$message.success("操作成功！");
        });
      });
    
    },
    getList(current) {
      if (!this.validatenull(current)) {
        this.pages.pageIndex = current;
      }
      agentUserList({ ...this.query, ...this.pages }).then(
        (res) => {
          this.dataSource = res.data.data.records;
          this.total = res.data.data.total;
        }
      );
    },

    initData() {
      this.$store.dispatch("fetchAgentListAll");
      this.getList();
    },
  },

  mounted() {
    this.initData();
  },
};
</script>
